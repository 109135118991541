exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bug-js": () => import("./../../../src/pages/bug.js" /* webpackChunkName: "component---src-pages-bug-js" */),
  "component---src-pages-cancellation-js": () => import("./../../../src/pages/cancellation.js" /* webpackChunkName: "component---src-pages-cancellation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kyc-js": () => import("./../../../src/pages/kyc.js" /* webpackChunkName: "component---src-pages-kyc-js" */),
  "component---src-pages-like-js": () => import("./../../../src/pages/like.js" /* webpackChunkName: "component---src-pages-like-js" */),
  "component---src-pages-matching-js": () => import("./../../../src/pages/matching.js" /* webpackChunkName: "component---src-pages-matching-js" */),
  "component---src-pages-message-js": () => import("./../../../src/pages/message.js" /* webpackChunkName: "component---src-pages-message-js" */),
  "component---src-pages-other-js": () => import("./../../../src/pages/other.js" /* webpackChunkName: "component---src-pages-other-js" */),
  "component---src-pages-pin-js": () => import("./../../../src/pages/pin.js" /* webpackChunkName: "component---src-pages-pin-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-report-js": () => import("./../../../src/pages/report.js" /* webpackChunkName: "component---src-pages-report-js" */),
  "component---src-pages-resignation-js": () => import("./../../../src/pages/resignation.js" /* webpackChunkName: "component---src-pages-resignation-js" */),
  "component---src-pages-safety-guide-js": () => import("./../../../src/pages/safety-guide.js" /* webpackChunkName: "component---src-pages-safety-guide-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-subscription-js": () => import("./../../../src/pages/subscription.js" /* webpackChunkName: "component---src-pages-subscription-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-timer-js": () => import("./../../../src/pages/timer.js" /* webpackChunkName: "component---src-pages-timer-js" */),
  "component---src-templates-help-post-js": () => import("./../../../src/templates/help-post.js" /* webpackChunkName: "component---src-templates-help-post-js" */)
}

